<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-label-multiple</v-icon> Fijación de precios</h1><br>
        <v-form ref="formPrecios">
          <v-layout>
            <v-flex xs3>
              <v-select
                label="Producto a liquidar"
                v-model="producto"
                outlined
                :items="productos"
              ></v-select>
            </v-flex>
            <v-flex xs3 v-if="producto=='Aceituna'">
              <v-select
                label="Categoría del producto"
                v-model="categoriaAceituna"
                outlined
                :items="categoriasAceituna"
                item-text="nombre"
                item-value="id"
              ></v-select>
            </v-flex>
            <v-flex xs3 v-if="producto=='Cascara'">
              <v-select
                label="Categoría del producto"
                v-model="categoriaCascara"
                outlined
                :items="categoriasCascara"
                item-text="nombre"
                item-value="id"
              ></v-select>
            </v-flex>
            <v-flex xs3 v-if="producto=='Frutales'">
              <v-select
                label="Categoría del producto"
                v-model="categoriaFrutales"
                outlined
                :items="categoriasFrutales"
                item-text="nombre"
                item-value="id"
              ></v-select>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                label="€ / Kg"
                v-model="eurokilo"
                outlined
                min=0
                step=1
                type="number"
              ></v-text-field>
            </v-flex>
            <v-flex xs1>
              <v-text-field
                label="Inicio campaña"
                v-model="campanaInicio"
                outlined
                min=0
                step=1
                type="number"
                style="margin-right: 0;"
                @change="campanaInicioChange"
              ></v-text-field>
            </v-flex>
            <span style="margin: 1% 1%"> / </span>
            <v-flex xs1>
              <v-text-field
                label="Fin campaña"
                v-model="campanaFin"
                outlined
                min=0
                step=1
                type="number"
                @change="campanaFinChange"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs1>
              <v-btn v-on:click="insert" text class="btnMini">
                <v-icon large>mdi-plus</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs3>
              <v-select
                label="Estado"
                v-model="estado"
                outlined
                :items="[{name: 'Activado', value: 1}, {name: 'Desactivado', value: 0}]"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                label="Escandallo"
                v-model="escandallo"
                outlined
                min=0
                step=0.5
                type="number"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-select
                label="Tipo de fijación"
                v-model="fijacion"
                outlined
                :items="[{name: 'General', value: 'general'}, {name: 'Por código postal', value: 'cp'}, {name: 'Por productor', value: 'productor'}]"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-flex>
            <v-flex xs2 v-if="fijacion=='cp'">
              <v-text-field
                label="Código postal"
                v-model="cp"
                outlined
                min=0
                step=1
                type="number"
              ></v-text-field>
            </v-flex>
            <v-flex xs2 v-if="fijacion=='productor'">
              <v-select
                label="Productor"
                v-model="productor"
                outlined
                multiple
                :items="this.$store.state.productor"
                item-text="nombre"
                item-value="id"
              ></v-select>
            </v-flex>
         </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table
          :headers="headersResumen"
          :items="resumen"
        >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="editarPrecio(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="eliminarPrecio(item.id)">
            mdi-delete
          </v-icon>
        </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import genericReload from '../components/genericReload'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
export default {
  name: 'Precios',
  data () {
    return {
      producto: null,
      productos: ['Aceituna', 'Cascara', 'Frutales'],
      categoriaAceituna: '',
      categoriasAceituna: [],
      categoriaCascara: '',
      categoriasCascara: [],
      categoriaFrutales: '',
      categoriasFrutales: [],
      campanaInicio: new Date().getFullYear(),
      campanaFin: new Date().getFullYear()+1,
      eurokilo: 0,
      escandallo: 0,
      estado: 1,
      cp: "",
      productor: [],
      fijacion: null,
      headersResumen: [
        {
          text: 'Campaña',
          align: 'start',
          sortable: false,
          value: 'campana',
        },
        { text: 'Producto', value: 'producto' },
        { text: 'Categoría / Calidad', value: 'categoria' },
        { text: 'Precio', value: 'precio' },
        { text: 'Escandallo', value: 'escandallo' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Estado', value: 'estado' },
        { text: "", value: "actions" },
      ],
      resumen: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
    this.loadProducts();
    this.read();
  },
  methods:{
    campanaInicioChange(){
      this.campanaFin = parseInt(this.campanaInicio)+1;
    },
    campanaFinChange(){
      this.campanaInicio = parseInt(this.campanaFin)-1;
    },
    loadProducts(){
      this.categoriasAceituna = this.$store.getters.productsByType("Aceituna");
      this.categoriasCascara = this.$store.getters.productsByType("Cascara");
      this.categoriasFrutales = this.$store.getters.productsByType("Frutales");
    },
    clear(){
      this.producto = null;
      this.campanaInicio = new Date().getFullYear();
      this.campanaFin = new Date().getFullYear()+1;
      this.categoriaAceituna = '';
      this.categoriaCascara = '';
      this.categoriaFrutales = '';
      this.eurokilo = 0;
      this.escandallo = 0;
      this.estado = 1;
      this.cp = "";
      this.productor = [];
      this.fijacion = null;
    },
    async insert(){
      if(this.producto != '' && this.fijacion != null){
        let cat = '';
        if(this.producto == "Aceituna"){
          cat = this.categoriaAceituna;
        }
        if(this.producto == "Cascara"){
          cat = this.categoriaCascara;
        }
        if(this.producto == "Frutales"){
          cat = this.categoriaFrutales;
        }
        
        const data = {
          idCategoria: cat,
          activo: this.estado,
          escandallo: this.escandallo,
          productores: this.productor,
          campana: this.campanaInicio+"/"+this.campanaFin,
          precio: this.eurokilo,
          cp: this.cp,
          tipo: this.fijacion,
        };

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+this.$store.state.auth
        };

        const response = await fetch(
          `${BASE_URL}/fijacionPrecio`,
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
          }
        );
        switch (response.status) {
          case 200:
            this.$refs.snackbar.setNew(true, "Datos guardados correctamente", "Ok", "green");
            this.read();
            this.clear();
            break;
          default:
            this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados", "Cerrar", "red");
            break;
        }
      }
    },
    async read(){
      this.resumen = [];
      const response = await genericReload(this.$store.state.auth, `fijacionPrecio`);
      if (response.status === 200) {
        const json = await response.json();
        let prod, cat, get, est, tip;
        for(let i = 0; i<json.body.length; i++){
          get = JSON.parse(JSON.stringify(this.$store.getters.productsById(json.body[i].idCategoria)));
          prod = get.producto;
          cat = get.nombre;
          est = (json.body[i].activo) ? "Activado" : "Desactivado";
          if(json.body[i].tipo == "general"){tip = "General";} if(json.body[i].tipo == "cp"){tip = "Por código postal";} if(json.body[i].tipo == "productor"){tip = "Por productor";}
          this.resumen.push({id: json.body[i].id, idCategoria: json.body[i].idCategoria, campana: json.body[i].campana, producto: prod, categoria: cat, precio: json.body[i].precio, escandallo: json.body[i].escandallo, tipo: tip, estado: est, estadoValue: json.body[i].activo});
        }
        await this.$store.dispatch('getPrecios');
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos", "Cerrar", "red");
      }
    },
    async eliminarPrecio(id){
      this.$refs.dialog.setNew("Eliminar precio", "¿Estás seguro de querer eliminar este precio? Debes de tener en cuenta que puede producirse una perdida de datos si este precio ya ha sido asignado previamente.", 0).then(async(confirm) => {
        if(confirm.response){
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/fijacionPrecio/${id}`,
            {
              method: 'DELETE',
              headers: headers,
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Precio eliminado correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Precio no eliminado", "Cerrar", "red");
              break;
          }
        }
      })
    },
    async editarPrecio(item){
      const get = JSON.parse(JSON.stringify(this.$store.getters.productsById(item.idCategoria)));
      const prod = get.producto;
      const cat = get.nombre;
      this.$refs.dialog.setNew("Editar precio", "", "editPrice", [prod, cat, item.campana, item.escandallo, item.precio, item.estadoValue]).then(async(confirm) => {
        if(confirm.response){
          const data = {
            escandallo: confirm.escandallo,
            precio: confirm.precio,
            activo: confirm.estado,
          };
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/fijacionPrecio/${item.id}`,
            {
              method: 'PUT',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Precio editado correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Precio no editado", "Cerrar", "red");
              break;
          }
        }
      })
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
</style>